<template>
    <div id="nurse-application">
        <section id="nurse-hero">
            <img class="d-none d-sm-none d-md-block" src="/img/nurse_hero_img_notext.png" alt="">
            <img class="d-block d-sm-block d-md-none" src="/img/nurse_hero_img_mobile_notext.png" alt="">
            <div id="hero-text">
                <div class="main-container">
                    <div class="container">
                        <h2>SELCARE NURSING SDN BHD</h2>
                        <h3>
                            Home Nursing
                        </h3>
                        <p>
                            SELCARE Nursing strives to offer home care services in nursing, midwifery,
                            physiotherapy, general care and other disciplines for patients of all ages. The
                            services that we provide is a cost efficient way to deliver quality care in the
                            convenience of the patient’s home.
                        </p>
                        <p>
                            These services are intended for patients that require skilled nursing personnel
                            to assess, initiate and oversee nursing interventions. SELCARE will create
                            care plans to achieve goals based on the patient’s diagnosis i.e. preventive,
                            therapeutic, and rehabilitative actions.
                        </p>
                        <p>
                            We accept locum nurse, physios, medical assistants and care aids.
                            Please contact us at 013&#8209;4460366 / Hotline 1-800-22-6600 or email at <a href="mailto:register@selcarenursing.com">register@selcarenursing.com</a>
                        </p>
                        <div class="nav-bot">
                            <router-link class="float-left" to="/store/home-nursing/apply" :class="{ 'active' : !isPageCheckStatus }">Become a Selcare Home Nurse</router-link>
                            <router-link class="float-right" to="/store/home-nursing/apply?checkStatus=true" :class="{ 'active' : isPageCheckStatus }">Check Status</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="main-container mt-3">
            <div v-if="!isPageCheckStatus" class="container">
                <!-- Section Staff Registration -->
                <section v-if="currSection == 1">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <h3>Provider Registration</h3>
                        </div>
                    </div>

                    <div class="row" v-if="listErrors.length > 0">
                        <div class="col-md-12 col-sm-12">
                            <div class="alert alert-danger" role="alert">
                                <span>Sorry, looks like you forgot something.</span>
                                <ul class="mb-0">
                                    <li v-for="err in listErrors" :key="err.id">{{ err }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col sm-12">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h5>Personal information</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Full name</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Full name" v-model="personal_information.fullname">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Gender</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <select class="custom-select" v-model="personal_information.gender">
                                                <option v-if="!personal_information.gender" :value="null" selected disabled>Choose gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">NRIC / Passport no</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <toggle-button v-model="is_NRIC"
                                            :labels="{checked: 'NRIC', unchecked: 'Passport no'}" class="" :width="100"
                                            :color="{checked: '#ac1a1a', unchecked: '#fb122a'}"/>
                                            <input v-if="is_NRIC" type="text" class="form-control" placeholder="NRIC : 900909037678" maxlength="12" v-model="personal_information.id_card">
                                            <input v-else type="text" class="form-control" placeholder="Passport no : A000000000" v-model="personal_information.id_card">
                                            <div class="custom-file mt-2">
                                                <input type="file" class="custom-file-input"
                                                    id="inFileIdCardFront" lang="es"
                                                    @change="checkFileUpload" ref="idCardFrontFile">
                                                <label class="custom-file-label take-img-text"
                                                    for="inFileIdCardFront">
                                                    {{ personal_information.id_card_front_file? personal_information.id_card_front_file.name : `${ is_NRIC? 'NRIC front side' : 'Passport front page' }` }}
                                                </label>
                                            </div>
                                            <div class="custom-file mt-2">
                                                <input type="file" class="custom-file-input"
                                                    id="inFileIdCardBack" lang="es"
                                                    @change="checkFileUpload" ref="idCardBackFile">
                                                <label class="custom-file-label take-img-text"
                                                    for="inFileIdCardBack">
                                                    {{ personal_information.id_card_back_file? personal_information.id_card_back_file.name : `${ is_NRIC? 'NRIC back side' : 'Passport back page' }` }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Photo</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="custom-file mt-2">
                                                <input type="file" class="custom-file-input"
                                                    id="inPhoto" lang="es"
                                                    @change="checkFileUpload" ref="photoFile">
                                                <label class="custom-file-label take-img-text"
                                                    for="inPhoto">
                                                    {{ personal_information.photo_file? personal_information.photo_file.name : '' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Date of birth</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <datepicker input-class="form-control" :typeable="true" :calendar-button="true" calendar-button-icon="fa fa-calendar" v-model="personal_information.date_of_birth">
                                            </datepicker>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Nationality</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <select class="custom-select" v-model="personal_information.nationality">
                                                <option v-if="!personal_information.nationality" :value="null" selected disabled>Choose nationality</option>
                                                <option value="malaysia">Malaysian</option>
                                                <option value="non-malaysian">Non-malaysian</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Race</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <select class="custom-select" v-model="personal_information.race">
                                                <option v-if="!personal_information.race" :value="null" selected disabled>Choose race</option>
                                                <option v-for="race in races" :key="race.id" :value="race">{{ race }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Religion</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <select class="custom-select" v-model="personal_information.religion">
                                                <option v-if="!personal_information.religion" :value="null" selected disabled>Choose religion</option>
                                                <option v-for="religion in religions" :key="religion.id" :value="religion">{{ religion }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Permanent address</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Address line 1" v-model="personal_information.permanent_address.address_line_1">
                                            <input type="text" class="form-control mt-2" placeholder="Address line 2" v-model="personal_information.permanent_address.address_line_2">
                                            <input type="text" class="form-control mt-2" placeholder="City" v-model="personal_information.permanent_address.city">
                                            <input type="text" class="form-control mt-2" placeholder="Zipcode" v-model="personal_information.permanent_address.zipcode">
                                            <select class="custom-select mt-2" v-model="personal_information.permanent_address.state">
                                                <option v-if="!personal_information.permanent_address.state" :value="null" selected disabled>Choose state</option>
                                                <option v-for="state in states" :key="state.id" :value="state">{{ state }}</option>
                                            </select>
                                            <select class="custom-select mt-2" v-model="personal_information.permanent_address.country">
                                                <option v-if="!personal_information.permanent_address.country" :value="null" selected disabled>Choose country</option>
                                                <option value="my">Malaysia</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Current address</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.current_address_same_as_permanent">
                                            <label for="">Same as permanent address</label>
                                           <div v-if="!personal_information.current_address_same_as_permanent">
                                                <input type="text" class="form-control" placeholder="Address line 1" v-model="personal_information.current_address.address_line_1">
                                                <input type="text" class="form-control mt-2" placeholder="Address line 2" v-model="personal_information.current_address.address_line_2">
                                                <input type="text" class="form-control mt-2" placeholder="City" v-model="personal_information.current_address.city">
                                                <input type="text" class="form-control mt-2" placeholder="Zipcode" v-model="personal_information.current_address.zipcode">
                                                <select class="custom-select mt-2" v-model="personal_information.current_address.state">
                                                    <option v-if="!personal_information.current_address.state" :value="null" selected disabled>Choose state</option>
                                                    <option v-for="state in states" :key="state.id" :value="state">{{ state }}</option>
                                                </select>
                                                <select class="custom-select mt-2" v-model="personal_information.current_address.country">
                                                    <option v-if="!personal_information.current_address.country" :value="null" selected disabled>Choose country</option>
                                                    <option value="my">Malaysia</option>
                                                </select>
                                           </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Contact no</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Contact no" v-model="personal_information.contact_number">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">E-mail address</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="email" class="form-control" placeholder="E-mail address" v-model="personal_information.email">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Registration types</label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.is_registered_nurse">
                                                    <label for="">Registered Nurse</label>
                                                    <div class="row mb-3" v-if="personal_information.registration_types.is_registered_nurse" style="padding-left:25px;">
                                                        <div class="col-12">
                                                            <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.registered_nurse.is_pediatric_nurse">
                                                            <label for="">Pediatric Nurse</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.registered_nurse.is_palliative_nurse">
                                                            <label for="">Palliative Nurse</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.registered_nurse.is_orthopedic_nurse">
                                                            <label for="">Orthopedic Nurse</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.registered_nurse.is_geriatric_nurse">
                                                            <label for="">Geriatric Nurse</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.registered_nurse.is_renal_nurse">
                                                            <label for="">Renal Nurse</label>
                                                        </div>
                                                        <div class="col-md-10 col-12">
                                                            <input type="text" class="form-control" placeholder="Others" v-model="personal_information.registration_types.registered_nurse.others">
                                                        </div>
                                                        <!-- <div class="col-md-10 col-12 mt-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFileregistered_nurse" lang="es"
                                                                    @change="checkFileUpload" ref="registeredNurseFile">
                                                                <label class="custom-file-label"
                                                                    for="inFileregistered_nurse">
                                                                    {{ personal_information.registration_types.registered_nurse_file? personal_information.registration_types.registered_nurse_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.is_medical_assistant">
                                                    <label for="">Medical Assistant (MA)</label>
                                                    <!-- <div class="row mb-3" v-if="personal_information.registration_types.is_medical_assistant" style="padding-left:25px;">
                                                        <div class="col-md-10 col-12">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFileMedicalAssistant" lang="es"
                                                                    @change="checkFileUpload" ref="medicalAssistantFile">
                                                                <label class="custom-file-label"
                                                                    for="inFileMedicalAssistant">
                                                                    {{ personal_information.registration_types.medical_assistant_file? personal_information.registration_types.medical_assistant_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.is_physiotherapist">
                                                    <label for="">Physiotherapist</label>
                                                    <!-- <div class="row mb-3" v-if="personal_information.registration_types.is_physiotherapist" style="padding-left:25px;">
                                                        <div class="col-md-10 col-12">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFilePhysiotherapist" lang="es"
                                                                    @change="checkFileUpload" ref="physiotherapistFile">
                                                                <label class="custom-file-label"
                                                                    for="inFilePhysiotherapist">
                                                                    {{ personal_information.registration_types.physiotherapist_file? personal_information.registration_types.physiotherapist_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_types.is_general_care_aide">
                                                    <label for="">General Care Aide</label>
                                                    <!-- <div class="row mb-3" v-if="personal_information.registration_types.is_general_care_aide" style="padding-left:25px;">
                                                        <div class="col-md-10 col-12">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFileGeneralCareAide" lang="es"
                                                                    @change="checkFileUpload" ref="generalCareAideFile">
                                                                <label class="custom-file-label"
                                                                    for="inFileGeneralCareAide">
                                                                    {{ personal_information.registration_types.general_care_aide_file? personal_information.registration_types.general_care_aide_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Registration number</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_number.is_malaysian_nursing_board_number">
                                                    <label for="">Malaysian Nursing Board (APC)</label>
                                                    <div class="row mb-3" v-if="personal_information.registration_number.is_malaysian_nursing_board_number" style="padding-left:25px;">
                                                        <div class="col-md-10 col-12">
                                                            <input type="text" class="form-control" placeholder="Board number" v-model="personal_information.registration_number.malaysian_nursing_board_number">
                                                            <div class="custom-file mt-2">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFileMalaysianNursingBoardNumber" lang="es"
                                                                    @change="checkFileUpload" ref="malaysianNursingBoardNumberFile">
                                                                <label class="custom-file-label"
                                                                    for="inFileMalaysianNursingBoardNumber">
                                                                    {{ personal_information.registration_number.malaysian_nursing_board_number_file? personal_information.registration_number.malaysian_nursing_board_number_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_number.is_malaysian_physiotherapy_association_number">
                                                    <label for="">Malaysian Physiotherapy Association</label>
                                                    <div class="row mb-3" v-if="personal_information.registration_number.is_malaysian_physiotherapy_association_number" style="padding-left:25px;">
                                                        <div class="col-md-10 col-12">
                                                            <input type="text" class="form-control" placeholder="Association number" v-model="personal_information.registration_number.malaysian_physiotherapy_association_number">
                                                            <div class="custom-file mt-2">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFileMalaysianPhysiotherapyAssociationNumber" lang="es"
                                                                    @change="checkFileUpload" ref="malaysianPhysiotherapyAssociationNumberFile">
                                                                <label class="custom-file-label"
                                                                    for="inFileMalaysianPhysiotherapyAssociationNumber">
                                                                    {{ personal_information.registration_number.malaysian_physiotherapy_association_number_file? personal_information.registration_number.malaysian_physiotherapy_association_number_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <input type="checkbox" class="mr-2" placeholder="" v-model="personal_information.registration_number.is_medical_assistant_board_number">
                                                    <label for="">Medical Assistant Board</label>
                                                    <div class="row mb-3" v-if="personal_information.registration_number.is_medical_assistant_board_number" style="padding-left:25px;">
                                                        <div class="col-md-10 col-12">
                                                            <input type="text" class="form-control" placeholder="Board number" v-model="personal_information.registration_number.medical_assistant_board_number">
                                                            <div class="custom-file mt-2">
                                                                <input type="file" class="custom-file-input"
                                                                    id="inFileMedicalAssistantBoardNumber" lang="es"
                                                                    @change="checkFileUpload" ref="medicalAssistantBoardNumberFile">
                                                                <label class="custom-file-label"
                                                                    for="inFileMedicalAssistantBoardNumber">
                                                                    {{ personal_information.registration_number.medical_assistant_board_number_file? personal_information.registration_number.medical_assistant_board_number_file.name : 'Upload file' }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Expiry date</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <datepicker input-class="form-control" :typeable="true" :calendar-button="true" calendar-button-icon="fa fa-calendar" v-model="personal_information.expiry_date">
                                            </datepicker>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Able to stay in with patient?</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <toggle-button v-model="personal_information.is_stay_in"
                                            :labels="{checked: 'Yes', unchecked: 'No'}" class="" :width="100"
                                            :color="{checked: '#ac1a1a', unchecked: '#fb122a'}"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-muted text-right">
                                <button class="btn btn-secondary mr-2">Cancel</button>
                                <button class="btn btn-primary">Save</button>
                            </div> -->
                            </div>
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h5>Qualification</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Diploma cert</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input"
                                                    id="inFileQualificationDiplomaCert" lang="es"
                                                    @change="checkFileUpload" ref="qualificationDiplomaCertFile">
                                                <label class="custom-file-label"
                                                    for="inFileQualificationDiplomaCert">
                                                    {{ qualification.diploma_cert_file? qualification.diploma_cert_file.name : 'Upload file' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Degree cert</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input"
                                                    id="inFileQualificationDegreeCert" lang="es"
                                                    @change="checkFileUpload" ref="qualificationDegreeCertFile">
                                                <label class="custom-file-label"
                                                    for="inFileQualificationDegreeCert">
                                                    {{ qualification.degree_cert_file? qualification.degree_cert_file.name : 'Upload file' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Post basic cert</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input"
                                                    id="inFileQualificationPostBasicCert" lang="es"
                                                    @change="checkFileUpload" ref="qualificationPostBasicCertFile">
                                                <label class="custom-file-label"
                                                    for="inFileQualificationPostBasicCert">
                                                    {{ qualification.post_basic_cert_file? qualification.post_basic_cert_file.name : 'Upload file' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Others</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input"
                                                    id="inFileQualificationOthers" lang="es"
                                                    @change="checkFileUpload" ref="qualificationOthersFile">
                                                <label class="custom-file-label"
                                                    for="inFileQualificationOthers">
                                                    {{ qualification.others_file? qualification.others_file.name : 'Upload file' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-muted text-right">
                                <button class="btn btn-secondary mr-2">Cancel</button>
                                <button class="btn btn-primary">Save</button>
                            </div> -->
                            </div>
                            <div class="card mb-4">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-6 col-12">
                                            <h5>Current employment</h5>
                                        </div>
                                        <div class="col-md-6 col-12 text-md-right">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="noCurrentEmploymentCheck" v-model="noCurrentEmployment">
                                                <label class="custom-control-label" for="noCurrentEmploymentCheck">Unemployed</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="!noCurrentEmployment">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Name of employer</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Name of employer" v-model="current_employment.name_of_employer">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Address</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Address line 1" v-model="current_employment.address.address_line_1">
                                            <input type="text" class="form-control mt-2" placeholder="Address line 2" v-model="current_employment.address.address_line_2">
                                            <input type="text" class="form-control mt-2" placeholder="City" v-model="current_employment.address.city">
                                            <input type="text" class="form-control mt-2" placeholder="Zipcode" v-model="current_employment.address.zipcode">
                                            <select class="custom-select mt-2" v-model="current_employment.address.state">
                                                <option v-if="!current_employment.address.state" :value="null" selected disabled>Choose state</option>
                                                <option v-for="state in states" :key="state.id" :value="state">{{ state }}</option>
                                            </select>
                                            <select class="custom-select mt-2" v-model="current_employment.address.country">
                                                <option v-if="!current_employment.address.country" :value="null" selected disabled>Choose country</option>
                                                <option value="my">Malaysia</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Position</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="position" v-model="current_employment.position">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Phone number</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Phone number" v-model="current_employment.phone_number">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-muted">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="noCurrentEmploymentCheck" v-model="noCurrentEmployment">
                                        <label class="custom-control-label" for="noCurrentEmploymentCheck">Unemployed</label>
                                    </div>
                                </div> -->
                            </div>
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h5>Previous work experience</h5>
                                </div>
                                <div class="card-body">
                                    <div v-for="(work, ind) in previous_work_experiences" :key="work.id">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                                <label for="">Organization</label>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <input type="text" class="form-control" placeholder="Organization" v-model="work.organization">
                                            </div>
                                        </div>
                                        <div class="row mt-2" :class="{ 'mb-3' : previous_work_experiences.length > 1 && ind < (previous_work_experiences.length - 1) }">
                                            <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                                <label for="">Years of experience</label>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <input type="number" class="form-control" placeholder="0" v-model="work.years_of_experience">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-muted text-right">
                                    <button class="btn btn-secondary mr-2" @click="removeWorkExperience" v-if="previous_work_experiences.length != 1"> - </button>
                                    <button class="btn btn-secondary mr-2" @click="addWorkExperience"> + </button>
                                </div>
                            </div>
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h5>Reference</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row" v-for="(reference, ind) in references" :key="reference.id" :class="{ 'mt-3' : ind > 0 }">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Reference {{ ind+1 }}</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Name" v-model="reference.name">
                                            <input type="text" class="form-control mt-2" placeholder="Designation" v-model="reference.designation">
                                            <input type="text" class="form-control mt-2" placeholder="Company" v-model="reference.company">
                                            <input type="text" class="form-control mt-2" placeholder="Contact number" v-model="reference.contact_number">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-muted text-right">
                                <button class="btn btn-secondary mr-2">Cancel</button>
                                <button class="btn btn-primary">Save</button>
                            </div> -->
                            </div>
                            <div class="card mb-4">
                                <div class="card-body card-body-white">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5>Disclaimer</h5>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <h5>Privacy Notice and Announcement</h5>
                                            <p class="text-justify">Please take note that the Personal Data
                                                Protection Act, 2010 entitles us with certain rights to the use
                                                of your personal information. SELCARE NURSING SDN BHD values
                                                your privacy and strives to protect your personal information in
                                                compliance with the act aforementioned. Further details
                                                pertaining to the why, what, and how the information is gathered
                                                and to how it is used is explained in our privacy act. By
                                                providing your personal information stated in this form, you are
                                                hereby allowing us to collect and process your personal data for
                                                purposes to provide services to you, in compliance our privacy
                                                policy. Kindly refer to our privacy policy at www.selcare.com .
                                            </p>
                                            <div class="custom-control custom-checkbox mt-3">
                                                <input type="checkbox" class="custom-control-input" id="disclaimer1Check" v-model="disclaimer1Checked">
                                                <label class="custom-control-label" for="disclaimer1Check">
                                                    I agree and consent to the above.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-12">
                                            <h5>Declaration of Registered Person</h5>
                                            <p class="text-justify">I, hereby represent and warrant that all the
                                                information provided herein is correct, accurate and complete in
                                                all material and there are no omissions from any such
                                                information that result in any such information being materially
                                                incomplete, incorrect, or misleading.
                                            </p>
                                            <p class="text-justify">I shall indemnify, defend and hold harmless
                                                to SELCARE NURSING SDN BHD and its respective officers,
                                                directors, employees, agents, successor and assign without
                                                limitation against any claim, demand, action, proceeding,
                                                prosecution, damages, fines, liabilities, loses, cost and
                                                expenses whether direct losses or foreseeable consequential
                                                loses, which may be made, instituted or imposed by the
                                                Appropriate Authority or any other person against the SELCARE
                                                NURSING SDN BHD caused or arising from gross negligence,
                                                reckless behaviour or wilful conduct of myself providing
                                                incorrect and/or incomplete information and/or omitted to any
                                                information that result in any such information being materially
                                                incomplete, incorrect, or misleading.</p>
                                            <div class="custom-control custom-checkbox mt-3">
                                                <input type="checkbox" class="custom-control-input" id="disclaimer2Check" v-model="disclaimer2Checked">
                                                <label class="custom-control-label" for="disclaimer2Check">
                                                    I agree and consent to the above.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-md-12 text-right">
                            <button v-if="!btnProcessing && (!disclaimer1Checked ||  !disclaimer2Checked)" class="btn btn-primary" disabled>Apply</button>
                            <button v-else-if="!btnProcessing && disclaimer1Checked &&  disclaimer2Checked" class="btn btn-primary" @click="submitApplication">Apply</button>
                            <button v-else class="btn btn-primary" disabled>
                                <span class="spinner-border spinner-border-sm"></span>
                                {{ btnProcessingValue }}
                            </button>
                        </div>
                    </div>
                </section>

                <!-- Section Finish / Thank you -->
                <section v-if="currSection == 2">
                    <div class="text-center">
                        <div class="card mb-5">
                            <div class="card-body card-body-white">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h2>Successfully Submitted!</h2>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="offset-md-2 col-md-8 col-sm-12">
                                        <p>
                                            Thank you for your registering your interest to be a part of SELCARE Nursing
                                            team. You will be notified via email once your application has been
                                            approved. <br />
                                            <b>Your application reference no. : <u>{{ reference_id }}</u></b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div v-else class="container">
                <section>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <h3>Check Your Application Status</h3>
                        </div>
                    </div>
                    <div v-if="isErrorCheckStatus" class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="alert alert-danger" role="alert">
                                <span>Sorry, looks like your reference id is wrong. Please type the right one.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5></h5>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Reference ID</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <input type="text" class="form-control" placeholder="Reference ID" v-model="referenceIDCheckStatus">
                                        </div>
                                    </div>
                                    <div v-if="resultCheckStatus" class="row mt-3 alert alert-warning">
                                        <div class="col-lg-4 col-md-4 col-sm-12 text-md-right">
                                            <label for="">Result :</label>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-12">
                                            <label v-if="resultCheckStatus == 'processing'" for="">Your application <u>is being processed.</u></label>
                                            <label v-else-if="resultCheckStatus == 'approved'" for="">Your application <u>has been approved.</u></label>
                                            <label v-else-if="resultCheckStatus == 'rejected'" for="">Your application <u>has been rejected.</u></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-muted text-right">
                                    <button v-if="!btnProcessing" class="btn btn-primary" @click="checkStatus">Check</button>
                                    <button v-else class="btn btn-primary" disabled>
                                        <span class="spinner-border spinner-border-sm"></span>
                                        {{ btnProcessingValue }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>


    </div>
</template>

<script>
import API from './../../../utils/API'
import Datepicker from 'vuejs-datepicker' 
import { ToggleButton } from 'vue-js-toggle-button'
import { required } from 'vuelidate/lib/validators'

export default {
    components: {
        Datepicker,
        ToggleButton
    },
    data() {
        return {
            isPageCheckStatus: false,
            referenceIDCheckStatus: null,
            resultCheckStatus: null,
            isErrorCheckStatus: false,
            currSection: 1,
            states: [
                'Johor',
                'Kedah',
                'Kelantan',
                'Kuala Lumpur',
                'Labuan',
                'Melaka',
                'Negeri Sembilan',
                'Pahang',
                'Penang',
                'Perak',
                'Perlis',
                'Putrajaya',
                'Sabah',
                'Sarawak',
                'Selangor',
                'Terengganu',
            ],
            races: [
                'Malay',
                'Chinese',
                'Indian',
                'Others'
            ],
            religions: [
                'Islam',
                'Christian',
                'Buddhist',
                'Hindu',
                'Others'
            ],
            disclaimer1Checked: false,
            disclaimer2Checked: false,
            is_NRIC: true,
            noCurrentEmployment: false,
            noPreviousEmployment: false,
            reference_id: null,
            /** Submission Data */
            personal_information: {
                fullname: null,
                gender: null,
                id_card: null,
                id_card_front_file: null,
                id_card_back_file: null,
                photo_file: null,
                date_of_birth: undefined,
                nationality: null,
                race: null,
                religion: null,
                permanent_address: {
                    address_line_1: null,
                    address_line_2: null,
                    city: null,
                    zipcode: null,
                    state: null,
                    country: null
                },
                current_address_same_as_permanent: false,
                current_address: {
                    address_line_1: null,
                    address_line_2: null,
                    city: null,
                    zipcode: null,
                    state: null,
                    country: null
                },
                contact_number: null,
                email: null,
                registration_types: {
                    is_registered_nurse: false,
                    registered_nurse_file: null,
                    registered_nurse: {
                        is_pediatric_nurse: false,
                        is_palliative_nurse: false,
                        is_orthopedic_nurse: false,
                        is_geriatric_nurse: false,
                        is_renal_nurse: false,
                        others: null
                    },
                    is_medical_assistant: false,
                    medical_assistant_file: null,
                    is_physiotherapist: false,
                    physiotherapist_file: null,
                    is_general_care_aide: false,
                    general_care_aide_file: null
                },
                registration_number: {
                    is_malaysian_nursing_board_number: false,
                    malaysian_nursing_board_number: null,
                    malaysian_nursing_board_number_file: null,
                    is_malaysian_physiotherapy_association_number: false,
                    malaysian_physiotherapy_association_number: null,
                    malaysian_physiotherapy_association_number_file: null,
                    is_medical_assistant_board_number: false,
                    medical_assistant_board_number: null,
                    medical_assistant_board_number_file: null
                },
                expiry_date: undefined,
                is_stay_in: true
            },
            qualification: {
                diploma_cert_file: null,
                degree_cert_file: null,
                post_basic_cert_file: null,
                others_file: null
            },
            current_employment: {
                name_of_employer: null,
                address: {
                    address_line_1: null,
                    address_line_2: null,
                    city: null,
                    zipcode: null,
                    state: null,
                    country: null
                },
                position: null,
                phone_number: null
            },
            previous_work_experiences: [
                {
                    organization: null,
                    years_of_experience: null
                }
            ],
            references: [
                {
                    name: null,
                    designation: null,
                    company: null,
                    contact_number: null
                },
                {
                    name: null,
                    designation: null,
                    company: null,
                    contact_number: null
                }
            ],
            /** Submission Data */

            btnProcessing: false,
            btnProcessingValue: 'Processing...',
            listErrors: []
        }
    },
    // validations: {
    // },
    watch:{
        $route(to, from){
            if(to.query.checkStatus && (to.query.checkStatus == 'true' || to.query.checkStatus == true))
                this.isPageCheckStatus = true
            else
                this.isPageCheckStatus = false
        }
    },
    methods: {
        checkStatus(){
            this.isErrorCheckStatus = false
            this.resultCheckStatus = null
            this.btnProcessing = true
            this.btnProcessingValue = 'Checking..'

            API.get(`/nurse-applications/search?reference_id=${this.referenceIDCheckStatus}`)
                .then(res => {
                    if(res.data.applications.length == 0) this.isErrorCheckStatus = true
                    else {
                        this.resultCheckStatus = res.data.applications[0].status
                    }

                    this.btnProcessing = false
                    this.btnProcessingValue = null
                })
        },
        checkFileUpload(){
            //qualification file
            // this.qualification.professional_qualification_file = this.$refs.professionalQualificationFile.files[0]
            if(this.$refs.idCardFrontFile && this.$refs.idCardFrontFile.files[0]) this.personal_information.id_card_front_file = this.$refs.idCardFrontFile.files[0]
            if(this.$refs.idCardBackFile && this.$refs.idCardBackFile.files[0]) this.personal_information.id_card_back_file = this.$refs.idCardBackFile.files[0]
            if(this.$refs.photoFile && this.$refs.photoFile.files[0]) this.personal_information.photo_file = this.$refs.photoFile.files[0]
            if(this.$refs.registeredNurseFile && this.$refs.registeredNurseFile.files[0]) this.personal_information.registration_types.registered_nurse_file = this.$refs.registeredNurseFile.files[0]
            if(this.$refs.medicalAssistantFile && this.$refs.medicalAssistantFile.files[0]) this.personal_information.registration_types.medical_assistant_file = this.$refs.medicalAssistantFile.files[0]
            if(this.$refs.physiotherapistFile && this.$refs.physiotherapistFile.files[0]) this.personal_information.registration_types.physiotherapist_file = this.$refs.physiotherapistFile.files[0]
            if(this.$refs.generalCareAideFile && this.$refs.generalCareAideFile.files[0]) this.personal_information.registration_types.general_care_aide_file = this.$refs.generalCareAideFile.files[0]
            if(this.$refs.malaysianNursingBoardNumberFile && this.$refs.malaysianNursingBoardNumberFile.files[0]) this.personal_information.registration_number.malaysian_nursing_board_number_file = this.$refs.malaysianNursingBoardNumberFile.files[0]
            if(this.$refs.malaysianPhysiotherapyAssociationNumberFile && this.$refs.malaysianPhysiotherapyAssociationNumberFile.files[0]) this.personal_information.registration_number.malaysian_physiotherapy_association_number_file = this.$refs.malaysianPhysiotherapyAssociationNumberFile.files[0]
            if(this.$refs.medicalAssistantBoardNumberFile && this.$refs.medicalAssistantBoardNumberFile.files[0]) this.personal_information.registration_number.medical_assistant_board_number_file = this.$refs.medicalAssistantBoardNumberFile.files[0]
            if(this.$refs.qualificationDiplomaCertFile && this.$refs.qualificationDiplomaCertFile.files[0]) this.qualification.diploma_cert_file = this.$refs.qualificationDiplomaCertFile.files[0]
            if(this.$refs.qualificationDegreeCertFile && this.$refs.qualificationDegreeCertFile.files[0]) this.qualification.degree_cert_file = this.$refs.qualificationDegreeCertFile.files[0]
            if(this.$refs.qualificationPostBasicCertFile && this.$refs.qualificationPostBasicCertFile.files[0]) this.qualification.post_basic_cert_file = this.$refs.qualificationPostBasicCertFile.files[0]
            if(this.$refs.qualificationOthersFile && this.$refs.qualificationOthersFile.files[0]) this.qualification.others_file = this.$refs.qualificationOthersFile.files[0]
        },
        async uploadFile(_file){
            let formData = new FormData()
            formData.append('files', _file)
            let resFileUpload = await API.post('/upload', formData)
            // console.log('res : ', resFileUpload.data)
            return resFileUpload.data[0].id
        },
        addWorkExperience(){
            this.previous_work_experiences.push({
                organization: null,
                years_of_experience: null
            })
        },
        removeWorkExperience(){
            this.previous_work_experiences.pop()
        },
        validateForms(){
            let errorArr = []

            /**  Personal Information */
            if(this.personal_information.fullname == null || this.personal_information.fullname == '') errorArr.push('(Required) Personal information - Fullname')
            if(this.personal_information.gender == null || this.personal_information.gender == '') errorArr.push('(Required) Personal information - Gender')
            if(this.personal_information.id_card == null || this.personal_information.id_card == '') errorArr.push('(Required) Personal information - ID card')
            // if(this.personal_information.id_card_front_file == null || this.personal_information.id_card_front_file == '') errorArr.push('(Required) Personal information - [ID card front] Need a file to be uploaded')
            // if(this.personal_information.id_card_back_file == null || this.personal_information.id_card_back_file == '') errorArr.push('(Required) Personal information - [ID card back] Need a file to be uploaded')
            // if(this.personal_information.photo_file == null || this.personal_information.photo_file == '') errorArr.push('(Required) Personal information - [Photo] Need a file to be uploaded')
            if(this.personal_information.date_of_birth == null || this.personal_information.date_of_birth == '') errorArr.push('(Required) Personal information - Date of birth')
            if(this.personal_information.nationality == null || this.personal_information.nationality == '') errorArr.push('(Required) Personal information - Nationality')
            if(this.personal_information.race == null || this.personal_information.race == '') errorArr.push('(Required) Personal information - Race')
            if(this.personal_information.religion == null || this.personal_information.religion == '') errorArr.push('(Required) Personal information - Religion')
            if(this.personal_information.permanent_address.address_line_1 == null || this.personal_information.permanent_address.address_line_1 == '') errorArr.push('(Required) Personal information - Permanent address - Address line 1')
            if(this.personal_information.permanent_address.address_line_2 == null || this.personal_information.permanent_address.address_line_2 == '') errorArr.push('(Required) Personal information - Permanent address - Address line 2')
            if(this.personal_information.permanent_address.city == null || this.personal_information.permanent_address.city == '') errorArr.push('(Required) Personal information - Permanent address - City')
            if(this.personal_information.permanent_address.zipcode == null || this.personal_information.permanent_address.zipcode == '') errorArr.push('(Required) Personal information - Permanent address - Zipcode')
            if(this.personal_information.permanent_address.state == null || this.personal_information.permanent_address.state == '') errorArr.push('(Required) Personal information - Permanent address - State')
            if(this.personal_information.permanent_address.country == null || this.personal_information.permanent_address.country == '') errorArr.push('(Required) Personal information - Permanent address - Country')
            if(!this.personal_information.current_address_same_as_permanent){
                if(this.personal_information.current_address.address_line_1 == null || this.personal_information.current_address.address_line_1 == '') errorArr.push('(Required) Personal information - Current address - Address line 1')
                if(this.personal_information.current_address.address_line_2 == null || this.personal_information.current_address.address_line_2 == '') errorArr.push('(Required) Personal information - Current address - Address line 2')
                if(this.personal_information.current_address.city == null || this.personal_information.current_address.city == '') errorArr.push('(Required) Personal information - Current address - City')
                if(this.personal_information.current_address.zipcode == null || this.personal_information.current_address.zipcode == '') errorArr.push('(Required) Personal information - Current address - Zipcode')
                if(this.personal_information.current_address.state == null || this.personal_information.current_address.state == '') errorArr.push('(Required) Personal information - Current address - State')
                if(this.personal_information.current_address.country == null || this.personal_information.current_address.country == '') errorArr.push('(Required) Personal information - Current address - Country')
            }else {
                this.personal_information.current_address.address_line_1 = this.personal_information.permanent_address.address_line_1
                this.personal_information.current_address.address_line_2 = this.personal_information.permanent_address.address_line_2
                this.personal_information.current_address.city = this.personal_information.permanent_address.city
                this.personal_information.current_address.zipcode = this.personal_information.permanent_address.zipcode
                this.personal_information.current_address.state = this.personal_information.permanent_address.state
                this.personal_information.current_address.country = this.personal_information.permanent_address.country
            }

            // if(this.personal_information.registration_types.is_registered_nurse == false && this.personal_information.registration_types.is_medical_assistant == false && this.personal_information.registration_types.is_physiotherapist == false && this.personal_information.registration_types.is_general_care_aide == false) errorArr.push('(Required) Personal information - Registration types - Please select one')
            // if(this.personal_information.registration_types.is_registered_nurse == true && this.personal_information.registration_types.registered_nurse_file == null) errorArr.push('(Required) Personal information - Registration types - [Registered Nurse] Need a file to be uploaded')
            // if(this.personal_information.registration_types.is_medical_assistant == true && this.personal_information.registration_types.medical_assistant_file == null) errorArr.push('(Required) Personal information - Registration types - [Medical Assistant] Need a file to be uploaded')
            // if(this.personal_information.registration_types.is_physiotherapist == true && this.personal_information.registration_types.physiotherapist_file == null) errorArr.push('(Required) Personal information - Registration types - [Physiotherapist] Need a file to be uploaded')
            // if(this.personal_information.registration_types.is_general_care_aide == true && this.personal_information.registration_types.general_care_aide_file == null) errorArr.push('(Required) Personal information - Registration types - [General Care Aide] Need a file to be uploaded')

            // if(this.personal_information.registration_number.is_malaysian_nursing_board_number == true && (this.personal_information.registration_number.malaysian_nursing_board_number == null || this.personal_information.registration_number.malaysian_nursing_board_number == '')) errorArr.push('(Required) Personal information - Registration number - Malaysian Nursing Board Number')
            // if(this.personal_information.registration_number.is_malaysian_nursing_board_number == true && this.personal_information.registration_number.malaysian_nursing_board_number_file == null) errorArr.push('(Required) Personal information - Registration number - (Malaysian Nursing Board) Need a file to be uploaded')
            // if(this.personal_information.registration_number.is_malaysian_physiotherapy_association_number == true && (this.personal_information.registration_number.malaysian_physiotherapy_association_number == null || this.personal_information.registration_number.malaysian_physiotherapy_association_number == '')) errorArr.push('(Required) Personal information - Registration number - Malaysian Physiotherapy Association Number')
            // if(this.personal_information.registration_number.is_malaysian_physiotherapy_association_number == true && this.personal_information.registration_number.malaysian_physiotherapy_association_number_file == null) errorArr.push('(Required) Personal information - Registration number - (Malaysian Physiotherapy Association) Need a file to be uploaded')
            // if(this.personal_information.registration_number.is_medical_assistant_board_number == true && (this.personal_information.registration_number.medical_assistant_board_number == null || this.personal_information.registration_number.medical_assistant_board_number == '')) errorArr.push('(Required) Personal information - Registration number - Medical Assistant Board Number')
            // if(this.personal_information.registration_number.is_medical_assistant_board_number == true && this.personal_information.registration_number.medical_assistant_board_number_file == null) errorArr.push('(Required) Personal information - Registration number - (Medical Assistant Board) Need a file to be uploaded')
            // if(this.personal_information.expiry_date == null || this.personal_information.expiry_date == '') errorArr.push('(Required) Personal information - Expiry date')
            if(this.personal_information.contact_number == null || this.personal_information.contact_number == '') errorArr.push('(Required) Personal information - Contact number')
            if(this.personal_information.email == null || this.personal_information.email == '') errorArr.push('(Required) Personal information - Email')
            /**  Personal Information */


            /**  Qualification */
            // if(this.qualification.diploma_cert_file == null) errorArr.push('(Required) Qualification - Diploma cert')
            // if(this.qualification.degree_cert_file == null) errorArr.push('(Required) Qualification - Degree cert')
            // if(this.qualification.post_basic_cert_file == null) errorArr.push('(Required) Qualification - Post basic cert')
            // if(this.qualification.others_file == null) errorArr.push('(Required) Others')
            // if(this.qualification.diploma_cert_file == null && this.qualification.degree_cert_file == null && this.qualification.post_basic_cert_file == null && this.qualification.others_file == null)
            //     errorArr.push('(Required) Qualification - Need at least one (1) file of certification')
            /**  Qualification */


            // /**  Current Employment */
            // if(!this.noCurrentEmployment) {
            //     if(this.current_employment.name_of_employer == null || this.current_employment.name_of_employer == '') errorArr.push('(Required) Current employment - Name of employer')
            //     if(this.current_employment.address.address_line_1 == null || this.current_employment.address.address_line_1 == '') errorArr.push('(Required) Current employment - Address - Address line 1')
            //     if(this.current_employment.address.address_line_2 == null || this.current_employment.address.address_line_2 == '') errorArr.push('(Required) Current employment - Current address - Address line 2')
            //     if(this.current_employment.address.city == null || this.current_employment.address.city == '') errorArr.push('(Required) Current employment - Address - City')
            //     if(this.current_employment.address.zipcode == null || this.current_employment.address.zipcode == '') errorArr.push('(Required) Current employment - Address - Zipcode')
            //     if(this.current_employment.address.state == null || this.current_employment.address.state == '') errorArr.push('(Required) Current employment - Address - State')
            //     if(this.current_employment.address.country == null || this.current_employment.address.country == '') errorArr.push('(Required) Current employment - Address - Country')
            //     if(this.current_employment.position == null || this.current_employment.position == '') errorArr.push('(Required) Current employment - Position')
            //     if(this.current_employment.phone_number == null || this.current_employment.phone_number == '') errorArr.push('(Required) Current employment - Phone number')
            // }
            // /**  Current Employment */


            // /**  Work Experience */
            // this.previous_work_experiences.forEach((work, ind) => {
            //     if(work.organization == null || work.organization == '') errorArr.push(`(Required) Work experience (${ind+1}) - Organization`)
            //     if(work.years_of_experience == null || work.years_of_experience == '') errorArr.push(`(Required) Work experience (${ind+1}) - Organization`)
            // })
            // /**  Work Experience */

            
            /**  References */
            // this.references.forEach((reference, ind) => {
            //     if(reference.name == null || reference.name == '') errorArr.push(`(Required) Reference (${ind+1}) - Name`)
            //     if(reference.designation == null || reference.designation == '') errorArr.push(`(Required) Reference (${ind+1}) - Designation`)
            //     if(reference.company == null || reference.company == '') errorArr.push(`(Required) Reference (${ind+1}) - Company`)
            //     if(reference.contact_number == null || reference.contact_number == '') errorArr.push(`(Required) Reference (${ind+1}) - Contact number`)
            // })
            /**  References */
            

            //show the result of the validation erros (if any)
            this.listErrors = errorArr

            //return the result of the validation
            if(errorArr.length > 0) return false
            else return true
        },
        async submitApplication(){
            //start btn processing
            this.btnProcessing = true
            this.btnProcessingValue = 'Uploading files..'

            //validate before submit
            //using custom simple validation
            if(!this.validateForms()){
                this.btnProcessing = false
                window.scrollTo(0,300) //scrolltop
                return ''
            }  

            //upload all files first and link it
            if(this.personal_information.id_card_front_file)
                this.personal_information.id_card_front_file = await this.uploadFile(this.personal_information.id_card_front_file)
            if(this.personal_information.id_card_back_file)
                this.personal_information.id_card_back_file = await this.uploadFile(this.personal_information.id_card_back_file)
            if(this.personal_information.photo_file)
                this.personal_information.photo_file = await this.uploadFile(this.personal_information.photo_file)
            if(this.personal_information.registration_types.registered_nurse_file)
                this.personal_information.registration_types.registered_nurse_file = await this.uploadFile(this.personal_information.registration_types.registered_nurse_file)
            if(this.personal_information.registration_types.medical_assistant_file)
                this.personal_information.registration_types.medical_assistant_file = await this.uploadFile(this.personal_information.registration_types.medical_assistant_file)
            if(this.personal_information.registration_types.physiotherapist_file)
                this.personal_information.registration_types.physiotherapist_file = await this.uploadFile(this.personal_information.registration_types.physiotherapist_file)
            if(this.personal_information.registration_types.general_care_aide_file)
                this.personal_information.registration_types.general_care_aide_file = await this.uploadFile(this.personal_information.registration_types.general_care_aide_file)
            if(this.personal_information.registration_number.malaysian_nursing_board_number_file)
                this.personal_information.registration_number.malaysian_nursing_board_number_file = await this.uploadFile(this.personal_information.registration_number.malaysian_nursing_board_number_file)
            if(this.personal_information.registration_number.malaysian_physiotherapy_association_number_file)
                this.personal_information.registration_number.malaysian_physiotherapy_association_number_file = await this.uploadFile(this.personal_information.registration_number.malaysian_physiotherapy_association_number_file)
            if(this.personal_information.registration_number.medical_assistant_board_number_file)
                this.personal_information.registration_number.medical_assistant_board_number_file = await this.uploadFile(this.personal_information.registration_number.medical_assistant_board_number_file)
            if(this.qualification.diploma_cert_file)
                this.qualification.diploma_cert_file = await this.uploadFile(this.qualification.diploma_cert_file)
            if(this.qualification.degree_cert_file)
                this.qualification.degree_cert_file = await this.uploadFile(this.qualification.degree_cert_file)
            if(this.qualification.post_basic_cert_file)
                this.qualification.post_basic_cert_file = await this.uploadFile(this.qualification.post_basic_cert_file)
            if(this.qualification.others_file)
                this.qualification.others_file = await this.uploadFile(this.qualification.others_file)

            this.btnProcessingValue = 'Finalizing...'

            //create reference id
            let resCount = await API.get('/nurse-applications/count')
            this.reference_id = `${this.personal_information.id_card}-${parseInt(resCount.data)+1}`

            //create and submit new form data
            let data2send = {
                is_NRIC: this.is_NRIC,
                reference_id: this.reference_id,
                personal_information: this.personal_information,
                registration_type: this.personal_information.registration_types,
                registration_number: this.personal_information.registration_number,
                qualification: this.qualification,
                current_employment: !this.noCurrentEmployment ? this.current_employment : null,
                previous_employment: !this.noPreviousEmployment ? this.previous_employment : null,
                previous_work_experiences: this.previous_work_experiences,
                references: this.references
            }
            // console.log('data2send : ', data2send)

            API.post('/nurse-applications', data2send)
                .then(res => {
                    this.btnProcessing = false
                    //go to thank you section
                    this.currSection = 2
                    window.scrollTo(0,0) //scrolltop
                })
                .catch(err => {
                    this.btnProcessingValue = 'Processing...'
                    this.btnProcessing = false
                    console.log('err', err.response.data)
                })

            //testing2 jer ning
            setTimeout(() => {
                this.btnProcessing = false
                this.currSection = 2
                window.scrollTo(0,0) //scrolltop
            }, 2000);
        }
    },
    async mounted(){
        if(this.$route.query.checkStatus && (this.$route.query.checkStatus == 'true' || this.$route.query.checkStatus == true))
            this.isPageCheckStatus = true
    }
}
</script>

<style scoped>
#nurse-hero {
    height: 315px;
    background-color: #aa1a19;
    position:relative;
}
#nurse-hero img {
    width:100%;
    height:100%;
    object-fit: cover;
}
#nurse-hero #hero-text {
    position:absolute;
    top:1px;
    left:1px;
    width:100%;
}
#nurse-hero #hero-text .container {
    padding-left:100px;
}
#nurse-hero #hero-text .container h2 {
    font-weight:bold;
    color:#ffffff;
    font-size: 35px;
    text-transform: uppercase;
}
#nurse-hero #hero-text .container h3 {
    margin-top: 20px;
    font-weight:bold;
    color:#ffffff;
    font-size: 30px;
    text-transform: uppercase;
}
#nurse-hero #hero-text .container p {
    color: #ffffff;
    width: 50%;
    font-size: 12px;
    line-height: 1.0;
    margin-bottom: 10px;
}
#nurse-hero #hero-text .container p a {
    color: #ffffff;
    text-decoration: underline;
}
#nurse-hero #hero-text .container .nav-bot{
    width: 50%;
}
#nurse-hero #hero-text .container .nav-bot a{
    color: #ffffff;
    text-decoration: underline;
}
#nurse-hero #hero-text .container .nav-bot a.active{
    color: #ffffff;
    font-weight: bold;
    text-decoration: unset;
}


#nurse-application button.btn-primary{
    background-color: #a91a18;
    border-color: #a91a18;
}

#nurse-application .card h5{
    font-weight: 900;
}

#nurse-application .card .card-header{
    background-color: #ffffff;
}

#nurse-application .card .card-body{
    background-color: #f6f6f6;
}
#nurse-application .card .card-body-white{
    background-color: #ffffff;
}

#nurse-application .card .card-footer{
    background-color: #ffffff;
}

/* Change bootstrap input focus */
select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border: 2px solid #a91a18;
  box-shadow: unset;
  /* border-color: #a91a18; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #a91a18; */
  outline: 0 none;
}

/** right checkbox  at card header*/
@media (min-width: 768px) {
    .card-header .custom-control.custom-checkbox{padding-left: 0;}

    .card-header label.custom-control-label {
        position: relative;
        padding-right: 1.5rem;
    }

    .card-header label.custom-control-label::before, .card-header label.custom-control-label::after{
        right: 0;
        left: auto;
    }
}
@media (max-width: 450px) {
    #nurse-hero {
        height: 190px;
    }
}

/** Handle text responsiveness for banner */
@media (max-width: 1196px) {
    #nurse-hero #hero-text .container {
        padding-left:50px;
    }
    #nurse-hero #hero-text .container p {
        width: 65%;
    }
    #nurse-hero #hero-text .container .nav-bot{
        width: 65%;
    }
}
@media (max-width: 992px) {
    #nurse-hero #hero-text .container {
        padding-left:0px;
    }
    #nurse-hero #hero-text .container p {
        width: 80%;
    }
    #nurse-hero #hero-text .container .nav-bot{
        width: 80%;
    }
}
@media (max-width: 767px) {
    #nurse-hero #hero-text .container {
        padding-left:0px;
    }
    #nurse-hero #hero-text .container h2 {
        font-size: 25px;
    }
    #nurse-hero #hero-text .container h3 {
        font-size: 20px;
        margin-top: 10px;
    }
    #nurse-hero #hero-text .container p {
        width: 80%;
    }
    #nurse-hero #hero-text .container .nav-bot{
        width: 80%;
    }

    /** Altered file input contents */
    .custom-file-label.take-img-text::after {
        content: 'Upload or take photo';
    }
}
@media (max-width: 575px) {
    #nurse-hero {
        top: 15px;
    }
    #nurse-hero #hero-text .container {
        padding-left:20px;
    }
    #nurse-hero #hero-text .container h2 {
        font-size: 25px;
    }
    #nurse-hero #hero-text .container h3 {
        font-size: 20px;
        margin-top: 10px;
    }
    #nurse-hero #hero-text .container p {
        width: 80%;
        font-size: 10px;
    }
    #nurse-hero #hero-text .container .nav-bot{
        width: 80%;
    }
}
@media (max-width: 449px) {
    #nurse-hero {
        top: 0px;
    }
    #nurse-hero #hero-text .main-container {
        padding-top:10px;
    }
    #nurse-hero #hero-text .container {
        padding-left:30px;
    }
    #nurse-hero #hero-text .container h2 {
        font-size: 16px;
    }
    #nurse-hero #hero-text .container h3 {
        font-size: 13px;
        margin-top: 10px;
    }
    #nurse-hero #hero-text .container p {
        width: 80%;
        font-size: 8px;
        line-height: 1.0;
        margin-bottom: 6px;
    }
    #nurse-hero #hero-text .container .nav-bot{
        width: 80%;
    }
    #nurse-hero #hero-text .container .nav-bot a{
        font-size: 9px;
    }
}
</style>